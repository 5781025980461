.service-menu {
    position: fixed;
    bottom: 0;
    left: auto;
    width: 100%;
    height: 200px;
    border-radius: 24px 24px 0px 0px;
    background: #1E1E2D;

    padding: 12px 32px 42px 32px;
    /* max-width: 390px; */
    box-sizing: border-box;
    z-index: 100;
    max-width: 500px;
}

.service-menu__title {
    color: #8A8AA3;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.service-menu .catalog__block-button__box {
    margin-top: 20px;
    height: 100%;
}

.service-menu__close {
    position: absolute;
    top: 12px;
    right: 12px;

}

.service-menu__bg {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    opacity: 0;
    background: #0F0F1B;
    transition: 0.2s linear opacity;
    pointer-events: none;
}

.service-menu__bg_open {
    opacity: 0.7;
    background: #0F0F1B;
    transition: 0.2s linear opacity;
    pointer-events: all;
    height: 1000vh;
}