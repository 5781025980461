.rules {
    position: relative;
    background: #1E1E2D;
    z-index: 1000;
    width: 100%;
    max-width: 414px;
    height: 100vh;
    padding: 132px 12px 120px 12px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow: scroll;
}

.rules__circle {
    position: absolute;
    top: 25px;
    left: 0px;
    z-index: 2;
}

.rules__star {
    position: absolute;
    top: 564px;
    left: 45%;
    z-index: 2;
}

.rules__cosmo-logo {
    position: absolute;
    width: 100%;
    max-height: 410px;
    right: 0;
    left: 0;
    top: 0;
    z-index: 0;
}

.rules__close {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1001;
}

.rules__title {
    text-align: center;
    font-family: Mayak;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.rules__title_big {
    color: #F1CE89;
    font-size: 62px;

    line-height: 66px;
    /* 106.452% */
    letter-spacing: 3.72px;
    margin-top: 165px;
}

.rules__title_middle {
    color: #D6A65D;
    font-size: 44px;
    line-height: 48px;
    /* 109.091% */
    letter-spacing: 3.52px;
    padding-bottom: 14px;
}

.rules__text {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.rules__text-block {
    display: flex;
    flex-direction: column;
    gap: 18px;
    border-top: 1px solid rgba(138, 138, 163, 0.3);
    padding-top: 30px;

    padding: 30px 16px 0 16px;
    width: 100%;
    box-sizing: border-box;
}

.rules__text-block_no-border {
    border: none;
    margin-top: -20px;
}

.rules__text-title {
    color: #F1CE89;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 120% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
}

.rules__text__defis {
    position: absolute;
    top: 0;
    left: -22px;
    width: 14px;
}

.rules__text-title_first {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.64px;
    text-transform: uppercase;
}

.rules__text-block_base {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.rules__text_preambula {
    color: #D6A65D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.56px;
    margin-top: 76px;
}

.rules__text_base {
    color: #CBCCD8;

    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    /* 128.571% */
}

.rules__text_base-box {
    display: flex;
    gap: 8px;
    position: relative;
}

.rules__text_base_yellow {
    color: #DEB75D;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__text_base_white {
    color: #fff;

}

.rules__comfort-rest {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.rules__comfort-rest__variant {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rules__comfort-rest__text_base {
    display: flex;
    flex-direction: column;
}

.rules__comfort-rest__text_yellow {
    color: #DEB75D;
    text-align: right;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__comfort-rest__text_base span {
    color: #ABADB7;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
}

.rules__signature {
    display: flex;
    gap: 21px;
}

.rules__signature span {
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
    text-transform: none;
}

.rules__signature p {
    color: #DEB75D;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.96px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rules__text_end {
    color: #D6A65D;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.56px;
}

.rules__logo_end {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}