@font-face {
    font-family: 'Nunito Sans';
    src: url('NunitoSans10pt-Medium.eot');
    src: url('NunitoSans10pt-Medium.eot?#iefix') format('embedded-opentype'),
        url('NunitoSans10pt-Medium.woff2') format('woff2'),
        url('NunitoSans10pt-Medium.woff') format('woff'),
        url('NunitoSans10pt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

