.subcat-nav {
  width: 100%;
  position: fixed;
  max-width: 500px;
}

.subcat-nav__swiper {
  width: 100%;
  height: 100%;
  max-height: 70px;
  background: #1e1e2d;
  box-sizing: border-box;
  padding: 0 18px !important;
}

.subcat-nav__swiper .swiper-slide {
  width: fit-content !important;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
}

/* .subcat-nav__slide_size_wide {
  width: calc(100vw - 38px);
} */

.subcat-nav__link {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 80px;
  background: #262637;
  box-sizing: border-box;
  padding: 10px 16px;
}

.subcat-nav__link_active {
  pointer-events: none;
  user-select: none;
}

.subcat-nav__mark-box {
  width: 0;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.subcat-nav__mark-box_visible {
  width: 14px;
}

.subcat-nav__link-mark {
  width: 6px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #ffb31f;
}

.subcat-nav__link-text {
  color: #ffb31f;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.667%;
  transition: color 0.2s ease-in-out;
}

.subcat-nav__link_active .subcat-nav__link-text {
  color: #fff;
}
