@import url(./assets/fonts/NunitoSans/stylesheet.css);
@import url(./assets/fonts/Mayak/stylesheet.css);

body {
  margin: 0;
  font-family: 'Nunito Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Nunito Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

:root {
  --text-color: #FFFFFF;
  --dark-text-color: #170936;
  --light-text-color: #D5BEFF;
  --bg-color: #1E1E2D;
}