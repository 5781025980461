.header-slide-menu {
    position: fixed;
    top: 0;
    left: auto;
    width: 100vw;
    height: 550px;
    border-radius: 0 0 24px 24px;
    background: #1E1E2D;

    padding: 20px 32px 42px 32px;
    /* max-width: 390px; */
    box-sizing: border-box;
    z-index: 99;


    display: flex;
    flex-direction: column;

    align-items: center;
    max-width: 500px;

}

.header-slide-menu .loading__star-bg {
    z-index: 1;
    /* top: -54px; */
}

.header-slide-menu__star {
    position: absolute;
    bottom: -13px;
    /* left: calc(48%); */
}

.header-slide-menu .catalog__logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-right: 10px;
}

.header-menu__bg {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    opacity: 0;
    background: #0F0F1B;
    transition: 0.2s linear opacity;
    pointer-events: none;
}

.header-menu__bg_open {
    opacity: 0.7;
    background: #0F0F1B;
    transition: 0.2s linear opacity;
    pointer-events: all;
    height: 1000vh;
}