.subcategory-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  scroll-margin-top: 120px;
  padding-bottom: 152px;
  margin-top: 46px;
  z-index: 1;


  max-width: 500px;
}

.subcategory-page_with-btn {
  padding-bottom: 92px;
}

.subcategory-page__products-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  padding: 0 18px;
  margin-top: 78px;
}

.subcategory-page__nothing-text {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.72px;
  padding: 100px 12px 0;
}

.subcategory-page__preloader {
  margin-top: 100px;
}

.subcategory-page__blur {
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg,
      rgba(30, 30, 45, 0) -7.25%,
      #1e1e2d 88.31%);
  position: fixed;
  bottom: -42px;
  pointer-events: none;
  z-index: 2;
}


.subcategory-page__promo {
  margin: 40px 0 0;
  text-align: center;
  color: #cbccd8;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.subcategory-page__promo-link {
  color: #FFB31F;
}