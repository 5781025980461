.catalog {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    /* justify-content: flex-end; */

    position: relative;
    max-height: 844px;
    overflow-x: hidden;

    max-width: 500px;
}

.catalog .loading__line__box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0 0 -200px 0;
    max-height: 214px;
    overflow: hidden;
}

.catalog .loading__line {
    margin-bottom: -197px;
}

.catalog .header-title {
    padding: 13px 0;
    margin: 0px -1px 0 0;
    height: 33px;
    display: flex;
    align-items: center;
}

.catalog__logo {
    width: 96px;
    height: 138px;
    z-index: 2;
    /* margin-left: -10px; */
    margin-top: 20px;
}

.catalog__line {
    /* margin-bottom: -199px;
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px; */

    margin-bottom: -199px;
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
}

.catalog__title-menu__box {
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin-top: 40px;
    z-index: 2;
}

.catalog__title-menu {
    color: #FFB31F;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    z-index: 2;
}

.catalog__block-button__box {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 40px;
}

.catalog__block-button {
    display: flex;
    width: 100%;
    height: 100%;

    max-width: 64px;
    max-height: 70px;

    padding: 16px 18px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    justify-content: center;

    border-radius: 20px;
    border: 1px solid #FFB31F;
}

.catalog__block-button__title {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 100% */
}

.catalog__bonus-card {
    display: flex;
    gap: 8px;
    margin-top: 24px;
}

.catalog__bonus-card span {
    color: #FFB31F;
}

.catalog__star_anim {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* top: 235px; */
    z-index: 2;
    pointer-events: none;
}

.star-shleif {
    margin-top: -7.5px;
}